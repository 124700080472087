import { PostProps } from "@/types"

const post17: PostProps = {
  key: 17,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Koh Rong Samloem',
  title: 'explowing the remote island and its stranges sea creatures',
  content: [
    {
      type: 'text',
      value: [
        `The ferry boat is fast, it jumps up and down on the water dramatically. Despite this, I feel sleepy. I am enjoying the smell of the sea.`,
        `I get off the boat at my pier, I properly look around and I gasp at the beauty. It feels very extra, almost like from a cliché travel magazine.`,
        `I pass by a small civilization - a street with a few restaurants and guesthouses. Then I walk towards nature. I have to walk up a little hill to get to my hostel. There is a girl behind me who is also climbing the hill. I talk to her and it turns out she goes to the same hostel.`,
        `The place is all in nature, there is basically no building with an 'inside'. Everything is outside, even the beds. The bar overlooks the sea, you can sip your coffee or smoothie and listen to the sound of the waves. The bathroom outside reminds me of bathrooms at summer festivals or camp sites, as I brush my teeth outside.`,
        `We check in together and since we keep talking, I ask her if she wants to have lunch with me - even though I am feeling sleep-deprived. We find a really nice restaurant a minute away from our hostel. Here, they serve really big portions, meals are about 3 dollars. The girl is from Germany and her name is Yvonne. We share some stories from our travels, compare our experiences and talk about what we do at home. Yvonne is a chef at a restaurant and that really caught my attention, since I do not know any chefs personally. She tells me fun stories from the kitchen of a Michelin star restaurant.`,
        `Instead of taking a nap, I join Yvonne and we go to the beach. We climb down our little hill, pass by the street with restaurants and walk on till we find a beautiful beach. We don't hesitate and get in the water. There are many crabs and fish, and we even find a strange shrimp that seems to chase us. Even after splashing water over it, it keeps wanting to reach us. We run out of the water, a bit traumatized. When we are ready again, we go back again - until some other creature scares us and then we repeat this. We are amazed by the amount of strange sea organisms here.`,
        `Later at night, we share some dinner at the same restaurant as before, and then spend some time at the bar. My mind is alert and I listen closely as Yvonne is talking, but my eyes keep shutting against my will. Around 10, I tell her I really need some sleep before I snooze mid-conversation.`,
        `Tonight's sleep is a great compensation for the missed sleep on the night bus. Perhaps it is the fact that we are outside, the soft buzzing of the fan, or the salt in the air, but I have had perhaps my best sleep of the trip so far.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post17/2.jpg',
          description: ['the hill towards the hostel'],
        },
        {
          src: '/images/01_southeast_asia/post17/3.jpg',
          description: ['crab traces'],
        },
        {
          src: '/images/01_southeast_asia/post17/4.jpg',
          description: ['the island looking like a painting'],
        },
        {
          src: '/images/01_southeast_asia/post17/6.jpg',
          description: ['arriving at the beach'],
        },
        {
          src: '/images/01_southeast_asia/post17/7.jpg',
          description: ['a crab we found'],
        },
        {
          src: '/images/01_southeast_asia/post17/8.jpg',
          description: ['zen at the beach'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Yvonne is still sleeping, so I take care of my morning plan. I eat breakfast at our restaurant, then take my yoga mat and walk down a steep, rocky staircase from the hostel bar to the bottom of the cliff. Here, I find a smooth and horizontal-enough rock and practise my yoga on it. Not gonna lie, it is a challenge, especially because it is noon now and the sun is shining bright on that rock. It still feels amazing. Afterwards, I jump in the water for refreshment. I am careful not to put my feet on the ground as I assume there might be sea urchins.`,
        `I walk back to the bar and meet up with Yvonne. We go to the beach again, this time a little further than before. Here, Yvonne is mostly enjoying snorkelling and floating about. I am enjoying swimming along the shore to the next pier, then I walk back and meditate on the beach.`,
        `Yvonne also gets out of the water and I bring her to the pier to show her some strange animals I have seen. I cannot really place them, they are circular, flat, hairy and have tentacles. Some of them are found underwater, others on the sand, some have buried themselves into the sand. We find more animals, like starfish, shells, crabs, snails. We observe them, and Yvonne has the courage to touch them too!`,
        `At the pier, we find many many shells and start collecting them and showing them to each other like children. We take a jump off the pier into the water full of little fish. These fish always swim together and sometimes jump out of water in big groups. Then we sit down in the sand and watch the sunset. Yvonne really knows how to enjoy life and I find it really inspiring.`,
        `After a big curry dinner at the restaurant street, we return to the beach for some fluorescent plankton show. We sit down in water, put on our goggles and look into the water. Whenever we make a movement, there are tens of little lights dancing around for a second. We keep making waves with our arms to observe this wonderful show. Walking around in the water also happens to be quite entertaining, a lot of tiny lights appearing around us.`,
        `This night is again quite peaceful. Sleeping outside seems to have a very positive effect on my sleep. The only thing that bothers me is a big, loud guy next to me. He stomps around dramatically and talks to himself loudly. He is either very drunk or he is sleep walking. Either way, as long as he continues his performance, no one can sleep.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post17/9.jpg',
          description: ['a look from the hostel bed'],
        },
        {
          src: '/images/01_southeast_asia/post17/10.jpg',
          description: ['the hostel bathroom'],
        },
        {
          src: '/images/01_southeast_asia/post17/11.jpg',
          description: ['the amazing bar'],
        },
        {
          src: '/images/01_southeast_asia/post17/12.jpg',
          description: ['a part of the bar overlooking the sea'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `In the morning, Yvonne and I joke about how no mosquitos bothered us - apart from one very big mosquito that kept everyone awake and would not stop buzzing!`,
        `Today, a big adventure awaits us again. After a nice breakfast in now our favorite restaurant, we decide to rent a kayak. The first thing we do is paddle to a little island closeby. We were mostly curious about the Island itself, the dense impenetrable jungle - but we did not know how amazing snorkelling would be here. We observe the rocks, find some live corals, follow fishes through their rock crevices. I am not realising the sun is slowly burning my back and my bum. Sometimes, we get the courage to touch something or pick up a shiny object. Every now and then, one of us urges the other to ‘quickly quickly check this out!!!’. Each time I snorkel I turn into an 8-year-old child at best.`,
        `We return to the main island, to the beach with all the restaurants and enjoy a big lunch there. We clearly need some break and calorie intake, since kayaking in the sea is a lot harder than I anticipated. And we plan to kayak quite a lot more.`,
        `The next journey is towards the edge of the island, beyond which we cannot see. The only way to get to the other side of the island is to get a boat (you cannot hike, as the jungle is way too dense, and there are probably dangerous monkeys in there too). I am hoping that with kayak, we can make it behind the edge and see the other side of the island, hopefully some of its beaches and civilization too.`,
        `As we are kayaking forward, I am realising that this plan might be a bit too ambitious. But we don’t want to give up. Eventually, we seem quite far already, so we park the kayak at a nice place and decide to swim towards the edge. I am barefoot and we start seeing a lot of sea urchins - the big kind. I never realized they do not only stay around rocky surfaces - these were all in the sand and also in quite shallow water! If I put my feet down, I could easily step on one. There are more and more of them, they start fully covering big sand patches. It becomes hard to rest my feet on the ground, so we decide to swim carefully back onto the shore. The shore is super rocky, but we decide to keep walking towards our goal. We must almost be there!`,
        `We keep finding beautiful shells. There are a lot of shy crabs constantly disappearing from our sight. At some point Yvonne is reaching towards a shell, when I notice a little black animal running towards the jungle. I cannot tell whether it was a stray dog or a monkey.`,
        `We keep getting further and further away from civilization and each time I think we are almost at the edge, another one presents itself. I am realising that each edge I am seeing is not the  one I targeted on the map. It is getting late. I keep cutting my feet on the sharp rocks. And Yvonne is worried about the possibility of us meeting a gang of monkeys. This is a time we both reach an agreement to respectfully give up and return to our boat.`,
        `We paddle with all our strength. The sea is really beautiful and I could easily get lost here, but we want to make it back around sunset - I would not want to kayak in the dark. I focus all my energy on each pull. And when I see the famous beach with the restaurants, I relax a little. Now the sun is setting down. We are faced with an unbelievable view. Watching the giant fireball falling into the wide wide sea. Watching this together from our kayak is magical.`,
        `We then paddle further and around the edge to find the station where we rented the boat. Here, we take some time to keep watching the post-sunset sea in silence. Yvonne le’s me enjoy this for a little longer and goes to inform the personnel that we are back.`,
        `Later in the night, we eat dinner at a restaurant that one of our roommates celebrated a lot. Finally, it is not completely packed. And indeed, it is quite worth it!`,
        `Last night, Yvonne and I spent time relaxing, reading, laying in the net above the coast at the hostel bar and talking. I feel lucky to have met such an adventurous soul again. Her zest for life inspires me.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post17/13.jpg',
          description: ['the wholesome sunset'],
        },
        {
          src: '/images/01_southeast_asia/post17/14.jpg',
          description: ['some of our collected treasures'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `After another peaceful night, I have to say goodbye to the beautiful nature hostel, to the island, and to Yvonne. We share a pizza brunch at one of the beach restaurants, then she walks me to the pier and waves me goodbye as I leave on one of the big, loud, bouncy ferries. She stays a few more nights, while I am on my way to cross the border and get back to my lovely Thailand. But not so fast - since I am going to work the upcoming days, I break up my journey into several days. I take my time and end up experiencing some truly strange places - but about that in the next post!`
      ]
    },
  ],
  signature: '05/04/2024 - 08/04/2024'
}

export default post17
