
import { PostProps } from "@/types"

const post11: PostProps = {
  key: 11,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Vang Vieng',
  title: 'Tipsy tubing, wallet scare, awesome techno and Mekong sickness',
  content: [ 
    {
      type: 'text',
      value: [
        `In my Vang Vieng hostel room, I meet Feli, a girl from Germany in an art college who is also a badass techno DJ, and a Vietnamese girl named In, a cool career woman and a strong sporty woman in one. They are both really sweet to me and invite me on a tipsy tubing.`,
        `Tipsy tubing is basically going on an inflatable tube down the river, stopping by at the bars by the river, getting tipsy (or drunk). I cannot really figure out whether I am feeling like doing this today, so I walk around the hostel trying to decide. I give up at the very last minute, almost when the taxi arrives, and jump on the tuktuk. Here, I meet Tony from Germany, and James and Sofia, an adorable couple from the UK. They show me an ideal image of a traveler couple - exploring together but still being very social and making everyone feel included.`,
        `We get onto the river, excited like little children. We are taken by the stream, trying to hold hands, making a little group of tubes, or sometimes a snake. Then letting go and finding each other again.`,
        `We reach the first bar within a few minutes. We order buckets of mojito and claim a whole table for our growing group. We talk and get a lot of inspiration from one another (everyone does something cool with their life here and I am pleasantly surprised that my remote job also causes amazed reactions). We leave our bags at the table and move closer to the bar, where a dance party is happening. We dance and try to bend backwards to cross a bar that two people are holding (I guess it is called limbo). They are sometimes rather unfair and put the bar so low it becomes physically impossible, but that makes for funnier falls. I got one such fall.`,
        `At some point, I wander off from the group. I embark into nature further from people to go pee. The noise from the party is now muffled. Here, I stand in silence and just stare at the mountains in awe. I am realizing that the whole Vang Vieng area is surrounded by sharp, thin, steep, dramatic green mountains.`,
        `We reach satisfactory tipsy levels and happily jump into our tubes to continue our journey through the river. In about 30 minutes, we are excited to see another bar. The way they get us out of the tubes is by throwing ropes at us. We can catch them and pull ourselves towards the shore. I am holding onto one, about one meter away from the shore and make a mistake thinking this is close enough to get off my tube. To my big surprise, I do not reach the ground but drench completely to the top of my head into the deep water. I come back above the water shocked, and quickly get help from someone to get onto the shore. I see this happening to more people behind me.`,
        `The first thing I do is check my fanny pack. It is not waterproof. My phone is not there, because I left it at home (thank God I did). What is worse, my wallet is not there either. And it was there at the previous bar. This gives me a big punch in the stomach. There is a lot of noise around me but I don't hear anything. My world slows down. People run to the bar to dance there and I only manage to say 'I'll catch you up!'. Tony is still next to me, and I choose him as my victim. 'My wallet is gone!' I turn to him.`,
        `We go sit to the side of the bar and I am holding back my tears. We tell the person in charge of this group what happened, and he makes a call. Apparently, he is sending someone on a boat to go search for my wallet at the other bar. I tell Tony I will be waiting to see what happens, and that he should go have fun. However, he refuses to leave me alone in my misery and stands by me the whole time.`,
        `I am thinking now what situation I got myself into. I do have a passport at the hostel, which is good. My bank card was in my wallet, so I could not withdraw money anymore, which would be a big complication. What is even worse, I need my ID card to sign my taxes electronically. If I cannot do my taxes here, I would need to cut my trip short and fly back to Slovakia. I am still trying not to cry. I need to stay rational.`,
        `After about 15 minutes, a guy I don't know is walking up to me with a big smile, showing me thumbs up. 'You have it? YOU HAVE IT???' I shout in disbelief. He waits until he walks all the way to me. Then he waves my bright green wallet in front of my face. I take it, start jumping, and, finally, I start crying. Now I can. Out of relief. I check the content of the wallet and everything still seems to be in it. I am not sure whether any cash is gone, some is still there, but since the currency is so weak, I carry quite large amounts and cannot always tell how much there is exactly.`,
        `I am wondering how this happened. I do not believe it fell out. Even when the fanny pack is open, it is rather physically impossible for anything to fall out of it, unless you shake it upside down for a while. Someone might have taken it out, but at this point I do not care. I am happy it made its way back to me. I swear to myself never to bring all my cards with me when I go out again. I run to the bar with Tony to celebrate this. We get a mojito bucket together and join the dancing squad. Still crying from relief. It is sunny, there are showers sprinkling water around, everyone is in a good mood. I am shouting excitedly through the loud music, explaining to people around me what just happened.`,
        `Now we are back on the river, continuing the big journey. Apparently, the next bar is three hours away! We make sure to stock up some drinks.`,
        `We go down the river, sometimes the stream gets strong and it gets really exciting, other times we are forced to lift our tubes, stand up, and walk through the rocky bottom of the river. Other times we are too lazy to do that and get our butts hurt on the rocks in the shallow regions. We make a lot of new, tipsy friends. I can see some people are really drunk at this point, but not really to the point where it would get dangerous. We are in a big group, not leaving anyone behind.`,
        `The sun goes down around 4 o'clock, due to the high mountains surrounding us. This is when the river gets really cold, and the weaker of us start shaking uncontrollably. I try to pee in the river but somehow it is super hard to relax my bladder like that, so I end up being distanced from the group for a good 15 minutes, trying to focus. The group tries to distract me, which makes my attempts really hard. Someone gives me advice to imagine it happening - and this really helps. I finally release all the mojito I drank.`,
        `Now it has been 4 hours on the river and no bar in sight. It takes some time until everyone gives up, one by one, and eventually, we reach a conclusion to finally leave the river. Here is a spot to do it, because there is a guy who is responsible for picking up our tubes. He calls us a tuktuk. A drunk girl makes out with a drunk guy, while we all wait in a circle ignoring them and talking. Waiting for the tuktuk.`,
        `Finally home! We couldn't wait for the warm shower and dinner. We relax a little before going to a techno party where Feli plays tonight. We hope there will be a lot of people showing up, since Feli and In did the party quite the promo. Especially In, she told about the party and then she went: 'There will be a super famous DJ from Germany there, her name is Feli, do you know her? She is super famous and super good!' It was funny seeing people react to this, and embarrassed Feli trying to get In to stop doing this.`,
        `First, we went to Deejai’s, which is a big vibrant bar, really full of people drinking, smoking, doing ballons. This is where everyone goes to in Vang Vieng. We met James, Sofia, Tony, and other people from the tipsy tubing, and some friends of friends. Tony left our group during tipsy tubing and apparently, he made it to the last bar! It took him really long and he was one of only a very few people there. Everyone else gave up and left the river sobered up and freezing to the bone.`,
        `Now is the time to move to Riverside, where the party happens, we get some beers and vibe to the DJ, before Feli comes on. Then she starts playing and more and more people appear on the dance floor. I am rather new to techno but exploring it through Feli's DJ-ing is perfect. She is really good. I am finding my rhythm. I meet some people here and there but I am more in the mood for listening to the music and dancing.`,
        `When Feli is done, she joins us at the dance floor and we give her the well deserved praise. Now people slowly stop dancing, start talking more, go smoking, and since I do not feel like smoking and the party has calmed down, I make my way home. The town is quite safe, so I feel good about this. Before going to bed, I sit down by the pool, relaxing and sobering up.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post11/1.jpg',
          description: ['tipsy tubing', 'picture borrowed from Feli'],
        },
        {
          src: '/images/01_southeast_asia/post11/2.jpg',
          description: ['Feli giving us some good rave'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `It is a slow morning. First I thought I was hungover, but now I am realizing I probably got sick again. A friend who also accidentally plunged into the Mekong river and got sick afterwards suggests that this might be my case too. It is possible I swallowed some of that nasty water. Now I keep going to the bathroom and my stomach is not okay either. The days are slowing down for me again and I have to accept it.`,
        `Today is about coffee, meditating, relaxing in the pool, and writing a diary. Writing helps me a lot because being sick again raises a lot of thoughts and feelings. It is an inner whirlwind again. I need to ground myself.`,
        `On Tuesday, I follow similar activities. On Wednesday, I get pretty bad, to the point that I cannot work on my laptop anymore. In the evening, to my surprise, I feel hunger and make plans with Feli to have dinner together.`,
        `Thursday, I am back on my feet, I go around looking for a good scooter. Eventually, I decide to take a trip tomorrow and stay at the hostel working.`,
        `It is lucky that I work in the evening, because thanks to this I meet Mada from Romania. She also works remotely (sometimes), and we both found out we are free tomorrow and during the weekend. We make a plan to go around and explore the caves and lagoons around, which is what Vang Vieng is famous for (besides the party scene).`
      ]
    },
  ],
  signature: '10/03/2024 - 14/03/2024'
}

export default post11
