
import { PostProps } from "@/types"

const post9: PostProps = {
  key: 9,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Luang Prabang',
  title: 'Exploring the nature tirelessly with a fellow Slovak',
  content: [ 
    {
      type: 'text',
      value: [
        `In the morning, Patrik and I eat  breakfast on the rooftop. It is included in the price of the hostel. I settle for eating a banana pancake every single morning.`,
        `It takes some time to find a good motorbike rental. Patrik is quite aggressive with haggling. Quickly enough, I learn he always does this and I am trying to learn - I am way too sensitive to argue with someone over price but Patrik does not take it so seriously - for him it is a fun game.`,
        `We drive for a bit towards the Kuang Si Waterfall. We park the motorbike and are asked to take a free ride on an electric bus to the top. First we have to walk through a rather disappointing zoo, but right behind, a beautiful waterfall is hidden.`,
        `It looks so gorgeous, it almost feels fake. I have never seen a place like this before. It is perfect. On the left side of the waterfall, there is a big group of Chinese tourists admiring the waterfall from a safe distance. They are taking pictures but it is not in their culture to walk over the bridge to take a hike or dive into the lakes. We assume they are worried it is not allowed, or they simply do not even consider the option. I heard a story about how people in China are only allowed to go knee-deep into the water. I am not sure whether this is true, but I know of many controlling and restricting rules there.`,
        `Laos is also a communist country. China has quite an influence over it. They help build their infrastructure, but in exchange, they take their resources. While on the slow boat trip on the Mekong, we saw some industrial sites that were most likely built and used by the Chinese. China is building a big railway track, which is already bringing large groups of Chinese tourists to Laos every single hour.`,
        `Luang Prabang is a strange town. A lot of Asian style houses, but also many French, back from the colonial times. On top of that, you occasionally see communist flags (red background, a golden hammer and sickle on top).`,
        `The banknotes all have the same guy on them. While I am in Laos, one euro is worth around 23000 Lao Kip. This makes me a millionaire! When buying something on the market, you can see the locals opening a giant bucket of cash they dive their hands into to give you change.`,
        `But back to the waterfall. Patrik and I take the hike route on the right and suddenly the loudness of the tourists fades out. We appear in a truly ethereal place. Something that looks like a forest halfway under the water. There are many lakes and smaller waterfalls, trees growing all around. The water has a heavenly color, sunbeams coming through the forest. Really large butterflies are flying around to cheer us up even more. Every now and then, we stop and go for a dive. Or climb some rocks to make it further into the waterfall. Or walk on a wooden log. This is the perfect place to play like a child, forget your worries. It looks like an absolute paradise. This is the most beautiful place I have been so far.`,
        `Eventually, we return to the base camp to get some food, but return to the waterfall again. Now we hike all the way to the top, where we find a flat surface, and a still river. The water here is more icy, refreshing. There is a swing over it so in case you want to swing, you have to dip in the water. The swing is a lot of fun. I swim a bit into the forest following the river but once I see a water snake, I swiftly return to the swing. Patrik is sad he has not seen it.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post9/1.jpg',
          description: ['the main waterfal from the front'],
        },
        {
          src: '/images/01_southeast_asia/post9/2.jpg',
          description: ['a little hidden watterfal further away'],
        },
        {
          src: '/images/01_southeast_asia/post9/3.jpg',
          description: ['swimming in one of the lakes in the forest'],
        },
        {
          src: '/images/01_southeast_asia/post9/4.jpg',
          description: ['the still river on top'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `We take a little boat ride to see where the river ends, then go back. After we hike down, we look at the main waterfall - the touristy spot - one more time. The waterfall seems as if it was pouring like soil, or salt, rather than water and this really messes with my brain. It looks really interesting and special.`,
        `On the way back to the hostel, we stop at a cafe in the middle of a rice field. We are enjoying a wonderful coffee and cake and here I am wondering whether today is real. The sugar overload is hitting me.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post9/6.jpg',
          description: ['the cafe in the rice field'],
        },
        {
          src: '/images/01_southeast_asia/post9/5.jpg',
          description: ['getting sugar overload in the little house'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Patrik and I get along well, so it seems like we are going to hang out every day. We meet at breakfast and then take a trip to a cave temple.`,
        `On the way there, we see someone riding an elephant and my stomach gets tense. Yesterday, we also drove by an ‘elephant sanctuary’, where elephants were chained by their ankles so that tourists can have a close look at them. I remind myself that this is already banned in Thailand and is perhaps going to change in Laos soon too.`,
        `We arrive in a very quiet, small and humble village. We walk down to the river and buy a ticket for a boat ride. All the river shuttles here are these really thin, long, colorful wooden boats.`,
        `The cave temple is famous for having a crazy amount of buddha statues. And that’s true, it is full of them, which makes the place really interesting to look around. There are not a lot of people around. Eventually, I see a tour guide explaining something to a couple. I try to listen to him inconspicuously.`,
        `Apparently, there are two types of buddhism: Theravada and Mahayana. Theravada is the oldest form of Buddhism, practised in Sri Lanka, Thailand, Myanmar, Cambodia, and Laos. The emphasis is on personal enlightenment achieved through meditation and discipline. Mahayana focuses on enlightenment for all beings, through compassion. It is practised in China, Japan, Korea, Tibet, and Vietnam. Interestingly enough, this branch of buddhism encourages vegetarianism more.`,
        `When walking around the outside of the temple, kids are constantly running after us to buy their bracelets. Many women are asking for donations for the temple, but we already paid entrance and it seems off that there would be so many different women asking for the donation (I would imagine just one at the entrance). This gets a little annoying and it becomes difficult to keep refusing them and setting boundaries.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post9/7.jpg',
          description: ['crossing the river'],
        },
        {
          src: '/images/01_southeast_asia/post9/8.jpg',
          description: ['the climb up into the temple'],
        },
        {
          src: '/images/01_southeast_asia/post9/9.jpg',
          description: ['entrance to the main temple from inside the cave'],
        },
        {
          src: '/images/01_southeast_asia/post9/10.jpg',
          description: ['a part of the statue display'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `In the village back on the other side of the river, we search for a restaurant, but with no luck. Patrik gets a durian ice cream but it turns out to be a terrible mistake. It tastes like soap and the taste stays with you for many hours after.`,
        `On the way home, we stop at the whiskey village. There was a big tradition to make and sell whiskey here, so almost every second family now has a stand offering some alcohol. The bottles are almost always decorated with some animal drenched in it, a snake, a lizard, a scorpion, a spider, you name it.`,
        `Patrik is interested in buying some as a souvenir. People at the stands happily pour us both shots to try. Patrik takes only a drop for the taste (since he is the driver). I finish all his shots (and mine too). Each time, we get a couple of whiskey shots and finish it with some rice wine (which I prefer over the whiskey). The journey home surely is funky for me.`
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post9/11.jpg',
          description: ['the distillation in progress'],
        },
        {
          src: '/images/01_southeast_asia/post9/12.jpg',
          description: ['trying out all the whiskey we possibly can'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `On Tuesday, our destination is another waterfall hike. We are not exactly sure where to find it, but we have an estimate. We take the motorbike and enjoy big wide fast roads, before we have to turn to a rocky dust road in nature. We are approaching a village, we can tell by the cattle chilling on the road with no stress. We drive by very slowly, trying not to disturb their peace.`,
        `The village is surrounded by large mountains, which makes it feel even more cozy. Compared to the village we saw yesterday, this one is maybe equal in size, but feels a lot more lively. We try to make comparisons to Slovak villages and one thing we notice is that people do not have fences. There is a lot of work going on, building houses and cottages, cooking, sewing. Hens are running around. People chatter, they spend time outside their houses - on the streets. No one seems to be bothered by two Slovak tourists on a loud motorbike.`,
        `Another thing I love about Southeast Asia is this community living. Sometimes I would see a big family sharing a meal on the street, with the grandparents, all aunties and uncles, and many children. The notion of family in Europe has been downsized to the most basic unit: the parents and the kids. Once we grow up, we move out. We take care of ourselves individually. Especially after visiting Asia, I feel like I recognize even more clearly how we move towards this hyper-individualism (especially in Western Europe).`,
        `At the end of the village, we find a man who owns some rental cottages and he tries to help us out. He says the waterfall on this side is dry, instead, we should take a U-turn and drive on the rocky road towards another waterfall. Before we gather strength to go on the bumpy road again, we have a nice lunch in the village.`,
        `This waterfall is also dried out. However, we find it rather interesting, looking at the cascading land where normally water would run down. We know that after a little hike, there is another part of the waterfall which is not completely dry yet. We walk even further and find a beautiful lake.`,
        `We take a swim. This is a very serene place, the jungle protects us from direct sunlight, some sunbeams breaking through the open spaces in the tree crowns. Again, we see many beautiful giant butterflies flying around. Being in this space is meditative.`,
        `On the way back, I realize Patrik is a really good driver and I don't mind him speeding on the wide asphalt roads. I realize I quite like the thrill of a really fast motorbike drive, as far as I see there is not much traffic around and the driver is skilled. The wind is whooshing in my ears and my hair flies around aggressively.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post9/13.jpg',
          description: ['entering the village'],
        },
        {
          src: '/images/01_southeast_asia/post9/15.jpg',
          description: ['the dried-up waterfall'],
        },
        {
          src: '/images/01_southeast_asia/post9/14.jpg',
          description: ['a bit less dry part further in the forest'],
        },
        {
          src: '/images/01_southeast_asia/post9/16.jpg',
          description: ['the lush jungle'],
        },
      ],
    },
  ],
  signature: '03/02/2024 - 05/03/2024'
}

export default post9
