
import { PostProps } from "@/types"

const post12: PostProps = {
  key: 12,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Vang Vieng',
  title: 'Exploring lagoons, hills and caves',
  content: [ 
    {
      type: 'text',
      value: [
        `It's Friday morning and I am meeting up with Mada at the hostel breakfast. I really like her, but cannot really pinpoint what exactly about her is so charming. It could be her bravery and confidence, her cute tattoos and style, or Eastern European relaxed mindset. Either way, we seem to be a good match for adventure.`,
        `Thanks to my research yesterday, we find a nice motorbike. Thanks to Patrik, I know how to haggle now and get us a nice price.`,
        `First we drive to lagoon number 4. Before even visiting the lagoon itself, there are plenty of activities around which we want to try out.`,
        `We first visit a cave. This one is special, it has water all along the cave. People explore the cave on a tube while holding a rope which is attached to the walls of the cave all the way to the end. We get a flashlight and jump into the cold water. It is really interesting exploring a cave this way, I never experienced anything like this. From time to time we just stop in the water and explore the formations on the walls. When we reach the end, we decide to turn off the light to experience how it feels to be in the complete darkness and silence inside of a cave. The only sound is the occasional movement of the water or dripping drops. It feels very meditative.`,
        `We come back to the warm bright sunlight all excited and refreshed. We go into another cave (just a regular cave) and come back for… a zipline!`,
        `The Zipline goes from one tree to another, with several rides in one. It is on a side of a mountain, so we can see the valley from it. There are really nice views and somehow, it is not scary at all. There are two men responsible for our safety, one of them goes before us, another one after us, attaching and re-attaching our carabiners. They fly through the ropes very swiftly, with almost no safety protection. This seems second nature for them. What a great job.`,
        `Before returning to the lagoon, we decide to get lunch just outside of the area. There is a group of local men, probably friends and family of the restaurant owners, but the moment we arrive, they stand up and leave the table, and go on to do something else. They free the restaurant for us, even though all the other tables are free. Mada points this out and I realize it is true. Locals don't feel comfortable sharing the space and perhaps out of politeness, leave the place even if there are plenty of tables for everyone. Even when we try to tell them they don't have to.`,
        `As we eat, we watch a cute group of kids playing in the river next to the restaurant. It’s a real aquapark there, with all the rocks and tiny waterfalls. Little did we know we would soon be the ones playing like these kids.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post12/1.jpg',
          description: ['exploring the cave on a tube'],
        },
        {
          src: '/images/01_southeast_asia/post12/2.jpg',
          description: ['the second cave'],
        },
        {
          src: '/images/01_southeast_asia/post12/3.jpg',
          description: ['ziplining down the forest'],
        },
        {
          src: '/images/01_southeast_asia/post12/4.jpg',
          description: ['our lunch spot'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Finally, we come back to the area to see the actual lagoon. There is a little zipline over the lake and next to it, a swing. We enjoy swimming in the cold lake, and together with other young tourists and vacationers, we swing and release into the water over and over again. In the end, I attempt a backflip. First I hold onto the swing with my hands, then I swing my legs back with all my power and release the swing. The best I manage is to dive head down.`,
        `Later in the day, we are relaxing on the sunbeds and slowly getting ready to leave. Here, we meet two adorable women, one from China and another one from Turkey, just dancing and playing around. Doing cartwheels and whatsoever. They seem so free. Naturally, we start interacting, laughing and playing around with them. We further find out what they do. One of them is a graphic designer, just like Mada. She is also a tattoo artist, and Mada has plenty of beautiful tattoos on her. I love seeing artists exchanging their work, seeing them talk about it with passion. I love artists. And meeting these girls is super refreshing. Just seeing the inner child so free in these adult women.`,
        `I am observing the beautiful sunset in between the mountains above the fields on the way home. Later, we eat dinner in the Full moon bar, get some drinks, watch some lanterns in the sky, and play pool. A great end to a great day.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post12/5.jpg',
          description: ['the cutest bike and helmets in Vang Vieng'],
        },
        {
          src: '/images/01_southeast_asia/post12/6.jpg',
          description: ['the dusty road with a nice view'],
        },
        {
          src: '/images/01_southeast_asia/post12/8.jpg',
          description: ['lagoon number 4', 'probably my favorite'],
        },
        {
          src: '/images/01_southeast_asia/post12/7.jpg',
          description: ['a bridge over the lagoon towards the caves'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Mada and I meet up at breakfast. Then I try to upgrade my sim but people in the stores seem clueless - I decide to buy a new one. We have more important things to sort out today (adventures).`,
        `We pick up the motorbike only to find out the rental lady removed our gasoline. Possibly because we had it cheaper than supposed to, and for a day only. She sees we are not happy about that and pours it back in.`,
        `The first stop of today is the wonderful, unbelievable Vang Vieng viewpoint with the iconic motorbike and a Lao flag. It takes us about 30 minutes to go up - some people from Google maps reviews reported this time, others talked about 1 hour, 1 hour and a half. It is a very steep hill but our conversations distract us from it. There are many tourists of all kinds of backgrounds here (not only westerners). The view is unbelievable. We do a little photoshoot with the bike, then enjoy the serenity and the view.`,
        `The next stop is lagoon 3. Here, we enjoy a nice lunch, relax, digest, and then move onto the cold water. There are many tubes in this water which makes it rather fun. There is also a zipline and a swing, but it is so slippery it is impossible to even hold onto it let alone attempt a backflip. We gasp at a girl carelessly and smoothly performing backflip after backflip - and everyone is clapping for her. No matter how hard I try, I can barely hold onto the swing for 1 second before it aggressively throws me into the water.`,
        `Right before leaving, we randomly realise there is a cave here too. Thank god we did! This cave is rather special.`,
        `The entrance to the cave is not guarded by anyone, no one lends you the lights, no one asks money for entry. There is information about it on a board, which introduces the cave in a bit of a scary way: ‘Bring a light with you. Always follow the arrows to not get lost. There is a single entry which is also the only exit. It takes about 30 to 60 minutes to complete your journey. You can walk till the end until you see a fence, then walk the same way back. On the way, you will find lakes.’`,
        `We are meeting some people and they freak us out a little. ‘There are no arrows there at all! I turned around and went back!’ says one girl. One of a group of men says: ‘This is the bravest thing I have ever done! I am so proud I did this!’ He shouts this out in a suspicious way, as if trying to announce this to the whole lagoon. His mate walking behind him says: ‘Don’t trust him, he turned around right at the entrance…’`,
        `Now we are standing at the entrance and we can finally see what all the fuss is about. The entrance to the cave is basically a tiny hole going into the ground, with a ladder. You have to climb down the ladder while squeezing through the tiny space to make it there. Here, I try to relax a little. But my mind is playing games on me. ‘What if a rock falls on us? What if I faint from lack of oxygen? What if we get lost?`,
        `But the thing is, the longer I am in this cave, the more natural the environment seems to me. My eyes are getting used to the darkness. My ears to the silence. My feet and hands to the wet and muddy surfaces. I am slowly getting into the present moment and out of my head. Out of panic mode and into a blissful euphoria.`,
        `We are going deeper and deeper into the cave - and luckily - there are arrows if you really pay attention. Every now and then we have to squeeze through a small opening to find a large space again. We walk and walk until we see a lake, where we take a little swim. There is only one guy there and I quite frankly adore his bravery - going into this cave alone and all the way to this lake. He leaves us alone and then we test again how it is to be without light and without sound, a complete meditative darkness.`,
        `On the way back, we get more courageous and we test whether getting lost is actually possible. We try to explore other routes and openings, but we always safely appear at the main route. We even find the fence which indicates the end of the cave. Behind the fence, there is a big deep hole. Closer to the entrance, we find a guy nearby who shouts: ‘People! Finally! I’ve been here for months!’ and we laugh.`,
        `We climb up the ladder through the small opening and appear in the daylight again. We are shocked to discover we spent one hour in the cave - it felt like 20 minutes!`,
        `There is still time for lagoon number 2. Here, there are more locals, big families enjoying a beautiful day. There is no zipline or swing, but a giant platform to jump from - I do not dare, it is way too high, but I adore Mada for doing it.`,
        `There is also a wobbly bridge going from one side of a lake to another. It is a challenge to walk or run through it without falling, but even a bigger challenge to do so when someone is standing on the other end, swinging the bridge to make it even more wobbly. Here, we make some friends: a Lao girl full of crazy fun energy and a shy western guy. We challenge each other to not fall from the bridge. The Lao girl is insanely good at this, she can even catwalk and twirl while we try to make her fall. Did she practise this?!`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post12/10.jpg',
          description: ['the classics'],
        },
        {
          src: '/images/01_southeast_asia/post12/12.jpg',
          description: ['lagoon 3'],
        },
        {
          src: '/images/01_southeast_asia/post12/9.jpg',
          description: ['the lake hidden deep inside the crazy cave'],
        },
        {
          src: '/images/01_southeast_asia/post12/11.jpg',
          description: ['lagoon 4 (with the wobbly bridge!)'],
        },

      ],
    },
    {
      type: 'text',
      value: [
        `Back in Vang Vieng, Mada and I share roti. We say goodbye, but we know we will still see each other on Monday in Vientiane, so it is not a sad goodbye yet.`,
        `I enjoy dinner at an amazing veggie place. Then I get an avocado smoothie from the street, but it turns out to be terribly painfully sweet. I can see the lady pouring a lot of yellow liquid into the blender and I ask her: ‘What is this?’, she replies with a big smile: ‘Sugar!’. I am learning that in Laos, you have to ask for a smoothie without sugar or condensed milk, if you want it to be healthy.`
      ]
    },
    {
      type: 'text',
      value: [
        `The next day, I am dedicating to some chores, equally as much as relaxing time. I am slowly saying goodbye to Vang Vieng - this crazy place.`,
        `It has given me a nice tipsy tubing with a lovely group. It has given me a wallet-losing scare - which happened to be a false alarm. I went to an awesome techno party. I have laid by the pool feeling sick for three days. But I recovered again and discovered all the natural beauty this place has to offer.`,
        `Vang Vieng is a little town in a valley surrounded by sharp steep mountains and wonderful sunsets. You can go canoeing on the river, fly in a hot air balloon and paraglide. More affordable adventuring would include renting a motorbike and exploring the lagoons and the caves - just like I did with Mada.`,
        `Vang Vieng also got really touristy and the main streets are full of hostels, bars, and smoothie - roti - sandwich shops. I was told by Patrik earlier about the famous Vang Vieng sandwiches - people love this town already just for these sandwiches.`,
        `But the strongest reputation is that this is a party place. And not only a fun fun summer party place, but really a crazy drug party place. There have been articles online about many people dying or going missing in Vang Vieng some years ago. Many of them due to adventuring on bad quality drugs or getting too drunk during tipsy tubing. Since then, things have gotten better. Maybe this is also the reason why there were not that many bars along the river during the tipsy tubing - people have speculated that some places had to be shut down due to aforementioned problems.`,
        `Still, it is super easy to find drugs in Vang Vieng. Weed is the most common - and weed is illegal in Laos. The way it works is that you can buy it easily in a bar, but then you also have to smoke it in the bar. If you go onto the street, there will be an undercover cop catching you and making you pay a large sum of money - ‘otherwise jail’ - they say. If the cops wanted to stop people using weed, they would target the sellers, but this way, everyone benefits. The silent rule is - do your drugs at the bar.`,
        `Last night in Vang Vieng, I got woken up by a guy screaming from top of his lungs some paranoid stuff outside the hostel. It was pretty scary, he thought someone was after him and they were going to kill him. I was glad I heard someone else approaching the guy and  calming him down. But this just shows the drugs in Vang Vieng are not to be trusted - maybe apart from the weed or balloons.`,
        `Despite this, I am saying goodbye to Vang Vieng as the place where I met incredible people. Where I had a lot of fun. A lot of relaxing and recovery. And last but not least - great adventures in nature. This is how I will remember Vang Vieng. I have had people tell me it is too much of a party place and I should skip it. I am glad I did not listen.`
      ]
    },
  ],
  signature: '15/03/2024 - 17/03/2024'
}

export default post12
