
import { PostProps } from "@/types"

const post13: PostProps = {
  key: 13,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Vientiane',
  title: 'The small capital, and the unpredictable journey to the south',
  content: [ 
    {
      type: 'text',
      value: [
        `A tuktuk picks me up at the hostel, just like it does every time I book a ticket through a hostel. I realize I accidentally threw out my bus ticket when doing an inventory of all the receipts in my wallet. I am now sitting in the bus and the driver is checking people’s tickets, but when he reaches my aisle, he just counts the passengers and leaves.`,
        `Upon arriving in Vientiane, I see mostly dirt roads and find it interesting how a capital city can be this underdeveloped. But once we get really into the centre, it starts to resemble a proper modern town. Nice asphalt roads, cute French-Asian colonial architecture. The town is small and humble, the buildings are small too. I am enjoying Vientiane, since it offers a bit of a western culture, which I have honestly been missing.`,
        `My hostel (1920) is a good example. It is designed to look retro, from the furniture to the music playing there. Downstairs, there is a cool cafe where they offer not only amazing coffee but also nice lunches.`,
        `I take care of some chores: getting a new sim card, visiting the post office to send all my postcards. It takes a little longer than I expected. It is now the afternoon and Mada is waiting for me in front of the post office, where we reunite.`,
        `It is good to see her again. We are exchanging stories from the little time we spent apart. We arrive at a cute cat cafe she discovered earlier. Here, we do a little coworking session. Not gonna lie, coworking with other people, in a cute place like this, and with cats around is quite motivating!`,
        `Later in the day, we take a break from working and enjoy a nice dinner in a Pakistani restaurant. In Asia, my most common meals are curry, fried rice, or butter paneer. If there is an option to eat Indian (or similar) cuisine, I go for that option (despite being in Laos). I also realise that we are currently only one minute from my hostel, so I make a mental note to come here again.`,
        `Mada tells me amazing stories about Barcelona, as she lived there for a few years and I will be spending a few months there soon.`,
        `I say goodbye to Mada, although we are hopeful we will meet again. I come back to the hostel and finish some work downstairs.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post13/1.jpg',
          description: ['cat cafe'],
        },
        {
          src: '/images/01_southeast_asia/post13/2.jpg',
          description: ['typical Vientiane street with a cool car'],
        },
        {
          src: '/images/01_southeast_asia/post13/3.jpg',
          description: ['the colonial architecture'],
        },
        {
          src: '/images/01_southeast_asia/post13/4.jpg',
          description: ['hostel 1920'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Today, I am walking a long, long way to a piercing studio - only to find out they are too busy to take me in. They ask me to come back at 3pm, although that is the time I would be working. Maybe getting my ear pierced is not meant for me right now.`,
        `On the way back, I make sure to visit the ‘Cope museum’, which is about the tragic consequences of the American bombing during the Vietnam war. Sadly, Laos became the most heavily bombed country in the world. After the war, the bombs kept exploding and causing people to lose limbs or even lives. Today, there are 80 million bombs still unexploded. It is getting a bit too hard to be in the museum and read about all of this, but I find it incredibly educational.`,
        `I take a peek at the market too, but I am not planning on buying anything. I work for the rest of the day, enjoy some food at the Pakistani restaurant, and for the first time since I came to Asia - some rain! It is a rainy evening and I notice I have missed the rain a lot.`,
        `I spend the rest of the evening working in the hostel cafe. By the time I am done, the cafe is full of camera crew - it seems they might be shooting a commercial here. It does not surprise me, given the wonderful authentic retro interior of this place. There is a cute dog as the main character and it is trained quite well to repeat the same thing over and over until they nail it.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post13/5.jpg',
          description: ['the map of the bombings', 'to give an idea of how bad it was'],
        },
        {
          src: '/images/01_southeast_asia/post13/6.jpg',
          description: ['my first Asian rain!'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `The next day, I am working a bit earlier in the day, as I plan to take a bus in the evening. I take a break to go to the gym quickly to help ease my back pain. Around 17, I am picked up at the hostel and brought to the bus station filled with giant sleeper buses. Funnily enough, I stumble upon a guy I met at the hostel in Luang Prabang, then again at a bar in Vang Vieng. He is travelling south too, but with a different bus.`,
        `Before we embark on the journey, I find a nice place to get some dinner and to use the toilet. There is no toilet on the sleeper bus, so it is a good call. The bus has two floors, and you share the bed with a stranger. Some people buy another ticket for about 25€, but I did not. It is truly a lottery and today I won. I am sharing the bed with a cute, small, young Chinese woman. We have a small talk before sleeping and the whole night, we both seem to have enough space and comfort.`,
        `The road is really bumpy, so I cannot really sleep. Instead, I just rest with my eyes closed and I try to entertain myself with my thoughts alone.`,
        `My plan was to go to Pakse, where people take a motorbike day trip to a beautiful waterfall - and then move on. But I am slowly realising I do not feel like jumping from location to location. I have seen enough waterfalls, this would be purely for tourism reasons - everyone says I should go there hence I should go there right? But I truly just want to go all the way south to chill on an island in a hammock. At that moment, I change my mind and decide to listen to what I really want. I decide to go all the way to the wonderful 4000 Islands at the complete south of the country.`,
        `The morning comes and we appear in Pakse. Everyone leaves the bus; some people are put in a tuktuk to go to the city, while others are placed in a comfortable van heading to the 4000 Islands. I ask if I can still buy a ticket, but I am told the vans are all full. I am then put in a tuktuk with locals and promised this would bring me to the bus station, where I can buy a ticket to the islands.`,
        `It takes a good hour, we go all around the city and pick up and drop off people, until we are finally at the bus station. I can see big tuktuks really really crammed with people, in a way I have never seen before. I walk to the station and ask around: ‘Where can I buy a ticket to 4000 Islands? Don Det?’. It takes me a good while until I understand where they are pointing me to. They are pointing at the crammed tuktuk.`,
        `Humbled, I allow my bag to be strapped on top of the vehicle, and I climb into the tuktuk. I can barely sit in a tiny space on a little wooden slat. My back is hunched over to make myself smaller in this tiny place - not great for my back pain. I am the last passenger who manages to get a seat, others are standing on the edge of the vehicle, holding onto the roof as we go. We follow the dirt road, and I watch the perfectly air-conditioned, fast vans overtake us at twice the speed. This will go on for 5 hours. How will I handle this?`,
        `I shift my gaze away from the road and into the vehicle. I look around the passengers. I realise this is how they travel - and they are perfectly happy. I scan their content faces. They seem to hide a lot of wisdom behind their wrinkled faces and kind eyes. I observe them and they observe me. I wish I knew their language and we could communicate. Sometimes we exchange some meaning, through the universal language of smiles and gestures. I decide this journey is going to be nice.`,
        `With some difficulty, I take my book out of my bag. I take some time to read, some time to meditate, some time to listen to the music, some time to just look around and let my mind wander. I am realising I love rides where there are no windows, you can feel and hear the wind, the sun is getting through to you. The summer heat and peace is prominent. The road is so loud but at the same time, everything seems so quiet, calm. Every now and then, we stop to drop off some passengers, pick up new ones, or allow someone to sell grilled meat to us.`,
        `I am dropped off in a town on the mainland, from where I am supposed to get to the islands. I pay the same amount as if I bought a ticket for the fancy vans. I refuse a tuktuk to the port in the hope of saving some money and decide to walk instead. I am told I need to pay a full price for the boat, or I could wait for other passengers. I am lucky to meet a Lao-French woman with two business partners in tourism, who bought the boat some time ago and let me on it.`,
        `My bungalow is very close to the port, maybe a one minute walk. I unpack, eat, sleep, exercise, and eat again. Both my meals had ants in them, but I didn't make a fuss since these cute, tiny ants seem to be everywhere. Even my bungalow exists in perfect harmony with nature. I plan to relax a lot. Take care of my back pain. I am excited for some me-time. But that is for tomorrow - now I will catch up on some sleep and release the sound of the tuktuk engine from my ears.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post13/7.jpg',
          description: ['dinner at night bus', 'look how tiny the beds are!'],
        },
        {
          src: '/images/01_southeast_asia/post13/8.jpg',
          description: ['the tuktuk I was crammed into'],
        },
        {
          src: '/images/01_southeast_asia/post13/9.jpg',
          description: ['finally a boat ride to the islands'],
        },
        {
          src: '/images/01_southeast_asia/post13/10.jpg',
          description: ['my lunch spot'],
        },
      ],
    },
  ],
  signature: '18/03/2024 - 21/03/2024'
}

export default post13