
import { PostProps } from "@/types"

const post10: PostProps = {
  key: 10,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Luang Prabang',
  title: 'Soaking up the town atmosphere',
  content: [ 
    {
      type: 'text',
      value: [
        `On Wednesday, we become aware of how tiring it is to go on adventures in the morning and work in the afternoon. Each morning, we meet up at the rooftop for breakfast, becoming more and more tired. Each evening, we play pool with less and less focus. So today, we decide to take a rest day and explore the beautiful Luang Prabang town.`,
        `I can tell that this is a fancy destination. There are really cool hotels with those ponds with lotus flowers and Buddha statues. Even the market is a little bit more fancy. The shops are focused on Laos heritage and you can bring home fancy souvenirs that look good in your living room. You can see a lot of rich old French and British people enjoying this town with deep curiosity. And, of course, a lot of Chinese tourists.`,
        `Patrik and I walk along the river and have to refuse all the offers for boat or tuktuk rides. They are extra naggy here. I have not really seen this behavior in Thailand, but as Patrik tells me, it is even crazier in Cambodia. Patrik has the same itinerary as I do, just in the opposite direction, so it is amazing that we can exchange a lot of tips on where to go next. We sit down for a beer and a smoothie (each to their own!)`,
        `For lunch, we discover an amazing restaurant, great view, great prices and a big amount of food, such that we struggle to finish. I order curry, Patrik tries his first Khao Soy. We share spring rolls.`
      ]
    },
    {
      type: 'text',
      value: [
        `On Thursday, we decide to explore the museums and temples. Luang Prabang is full of adorable cafés, so it is not hard to find a place for some afternoon caffeine dose. We sit in a wooden cottage, on the first floor, windows with views onto the quiet street. It is a combination of back-and-white photography gallery and a café. Patrik writes out all his postcards, I read them and laugh at the jokes he repeats in them. I don't blame him - it is hard to come up with something original and funny for every single person.`,
        `After sharing lunch at our new favorite lunch place, we go back to the hostel and meet an Italian guy, Luca. He is really laid-back and fun, and we discover he also really likes to play pool, so he becomes our new pool buddy.`,
        `I work a little, do some yoga, and swim in the hostel pool. In the evening, Patrik and I meet up with a Canadian friend (Walker), who always greets us: 'Hello, Slovakia!'. The three of us go to an open-air cinema and enjoy a movie called Chang. It was made in 1927 and it is about the jungle life in Laos. I believe it is the first movie ever made about this country. It is old and perfectly funny. There are many animals in the movie - some of which are not being treated well - so you have to sit through those scenes biting your nails. Other than that, I really recommend this strange movie.`,
        `We go to the market and talk with Walker about Canada, and then the politics and then pretty much about the whole world. I am really starting to crave sour tomatoes and I find a perfect meal here for it. Fried tofu in a tomato sauce. The market is now my favorite place for dinner.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post10/1.jpg',
          description: ['a little message at our favorite lunch place'],
        },
        {
          src: '/images/01_southeast_asia/post10/2.jpg',
          description: ['the national museum'],
        },
        {
          src: '/images/01_southeast_asia/post10/3.jpg',
          description: ['an interesting gas station'],
        },
        {
          src: '/images/01_southeast_asia/post10/4.jpg',
          description: ['postcards writing'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `On a Friday morning, we finally manage to see the temple on the hill, which is the iconic one in Luang Prabang. Here, the view is amazing. I am at peace . The only disturbing thing is seeing people selling birds in tiny cages just so that people can buy them and release them.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post10/5.jpg',
          description: ['resting at the temple on the top'],
        },
        {
          src: '/images/01_southeast_asia/post10/7.jpg',
          description: ['the view over the town and the Mekong river'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `We hunt for another cute café and Patrik writes out some postcards. Today's coffee and cake is on him, because of the international women's day.`,
        `Walking around the town, we stumble upon a UNESCO museum, where we get to walk around houses just how they used to have them back in the day, with all the artefacts inside that teach you about the daily life of Lao people (some of whom still live similarly today). I love reading about Buddhist life. The beating of the drum in the temple sets the routine of the day and announces an incoming celebration. Every month, there are 4 Buddhist celebrations. Additionally, people celebrate a lot of important milestones and life changes.`,
        `In the garden of this complex, we get a fancy sandwich but it does not fill us. I fixed it by having a smoothie and later an ice cream, while Patrik had another full lunch.`,
      ]
    },
    {
      type: 'images',
      value: [

        {
          src: '/images/01_southeast_asia/post10/9.jpg',
          description: ['another cute cafe'],
        },
        {
          src: '/images/01_southeast_asia/post10/10.jpg',
          description: ['little streets of Luang Prabang'],
        },
        {
          src: '/images/01_southeast_asia/post10/11.jpg',
          description: ['inside the heritage museum'],
        },
        {
          src: '/images/01_southeast_asia/post10/12.jpg',
          description: ['at the museum\s garden'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `In the evening, we go to a traditional Lao dance-music-theatre performance. The music features a lot of soft-sounding drums, and it seems like we are listening to the same song throughout the entire performance, with the biggest changes in rhythm or pace. There are people in beautiful costumes, men wearing king, animal or devil resembling costumes, women wearing beautiful dresses and dancing delicately with their hands and fingers. I wonder how they can bend their hands into such smooth and wavy movements. Everyone has this specific crown with a decoration on top of it. There is a story but for Patrik and I, without knowing the actual story, it is hard to understand what is being communicated. I do not know the terms or the exact cultural/religious meanings, so I perceive the performance with a completely open mind and with a lot of curiosity. I have never seen anything like this and I hope to see more such cultural performances.`,
        `We grab dinner at the market, and have a lot of fun exchanging nerdy jokes. Before we go back to the hostel, we get some snacks and beers. It is the last night at the hostel after all. On the rooftop, we meet Luca again and play pool all evening until we get exhausted and can no longer focus.`,
        `I say goodbye to Patrik, who is leaving tomorrow early morning. Thanks to him, I was able to find a drive for travel after being sad and tired for a while. I had a lot of fun with Patrik, saw many amazing places, learned tactics in pool, and enjoyed comfortable motorbike rides. It is actually quite likely that I might see him again, since he is the first travel buddy who lives in the same country I do!`,
        `The next day, I spend relaxing by the pool, reading and planning my journey in a café, and struggling with Slovak taxes on long distance. In the evening, I take a fancy train on the Chinese railway down the country towards a crazy place called… Vang Vieng.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post10/14.jpg',
          description: ['the traditional performance'],
        },
        {
          src: '/images/01_southeast_asia/post10/15.jpg',
          description: ['one last splurge at a cute cafe before taking the train'],
        },
      ],
    },
  ],
  signature: '06/02/2024 - 09/03/2024'
}

export default post10



