import { PostProps } from "@/types"

const post16: PostProps = {
  key: 16,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Siem Reap',
  title: 'temple hunt at Angkor Wat and overcoming another sickness',
  content: [
    {
      type: 'text',
      value: [
        `This morning, Claudia and I sit in the lobby, waiting for the tuktuk to pick her up. Here, we have to say goodbye. I thank her for all the beautiful conversations we had and as she leaves with the tuktuk, I grab my bike and go to another acupuncture session.`,
        `The second session feels a little rushed, as there are suddenly quite a lot of people in the clinic. I am not sure that the doctor remembers where my pain was, since she seems to place the needles in strange spots and I do not feel any progress. But I decide to trust the process and I plan to show up tomorrow too.`,
        `I meet up with Diana. Today, the plan is to visit Angkor on our bikes. It is the biggest complex of temples in the world. Many temples were built about a millennium ago. It is the reason why people visit Cambodia. I bought a three day long ticket for 62 dollars. It is simply way too big to see in just one day. It is also quite expensive given we are in Cambodia, but given its importance, it is justified.`,
        `The complex has around 72 major temples, scattered around in a forest of about 400 km squared. There is nothing else, apart from some shops, restaurants and roads. Just some ancient temples in a jungle. What a special place. On the side of the road, we can even see some monkeys. This excites me a lot since this is my first time seeing monkeys somewhere else than the zoo. `,
        `The first stop is Phnom Bakheng. It is a bit on top of a hill, so the view from here is quite amazing. Then we pass through a really cool gate with a line of statues of Khmer men on the sides and bike around Angkor Wat, the main and biggest temple (Didi already saw it and I will see it another day).`,
        `The next temple is Prasat Preah Khan which just looks like a big, long, complicated, rectangular, geometrically-perfect maze. The stones have a bit of a greenish tint which looks awesome. Here I learn that Tomb Raider, the movie with Angelina Jolie as the main lead, was shot here. Apparently, Angelina fell in love with Cambodia and set up a foundation to preserve the forests here.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post16/1.jpg',
          description: ['Phnom Bakheng temple'],
        },
        {
          src: '/images/01_southeast_asia/post16/2.jpg',
          description: ['the statues at the gates'],
        },
        {
          src: '/images/01_southeast_asia/post16/3.jpg',
          description: ['geometrically satisfying Prasat Preah Khan'],
        },
        {
          src: '/images/01_southeast_asia/post16/4.jpg',
          description: ['channeling my inner tomb raider'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Neak Poan temple comes next. At this point, we are used to ladies from the restaurants shout at us as we park our bikes. But what we experience now is a whole other level. One woman talks to Didi and another one approaches me.`,
        `‘Where do you come from? You are so kind! I would love to visit your place. Are you having fun in Cambodia? I would love to talk to you more, I feel like we can have a very nice conversation. Come eat at my restaurant, it is number 4. Okay??? Do not forget, number 4! Promise me. I will be waiting for you!’`,
        `The moment we lock our bikes we run away and shake them off.`,
        `The one who talked to you, did she tell you to come to restaurant number 4?’ I ask Didi. ‘No, she said number 7.’`,
        `I am in disbelief at the competition.`,
        `Neak Poan is a special one - first, there is a lake full of lotus flowers. We walk on a nice wooden bridge to get to a little island. Here, there is another lake and within it, another island on which you can see the temple. Breathtaking.`,
        `We come to get our bikes and the ladies rush to us, one shouts at me: ‘You promised me! Number 4, please come eat here!’, another one screams: ‘Number 7, remember?’`,
        `We are indeed super hungry, so we do have to eat here. But we choose a restaurant with a calm waitress. And she rewards us with so much food - I barely eat this much in a whole day!`,
        `We are taking our time, getting fuller and fuller and having interesting conversations about politics, racism, society, beauty standards, whitewashing, and so on. Heavy conversation, heavy bellies, hot weather. We somehow manage to still get on the bikes and continue.`,
        `Ta Som temple is quite nice, but simple. Eastern Mebon is breathtaking - it has gorgeous elephant statues and several levels.`,
        `It is now almost 17, meaning the temples are about to close down. We arrive at Pre Rip temple but we only have a few minutes left. The staff does not let people in anymore. I want to give up, but Diana is trying to convince them. And it’s a good thing she is, because it is working! Everyone around us who also wants to get in is cheering. I adore Diana’s optimism - it stands out even more when I feel more negative or want to give up. We climb to the top of the temple and enjoy a beautiful view over the jungle as the sun is setting down.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post16/5.jpg',
          description: ['the bridge over the lake, entering Neak Poan temple'],
        },
        {
          src: '/images/01_southeast_asia/post16/6.jpg',
          description: ['the little temple on the innermost lake'],
        },
        {
          src: '/images/01_southeast_asia/post16/7.jpg',
          description: ['elephant statues at Eastern Mebon'],
        },
        {
          src: '/images/01_southeast_asia/post16/8.jpg',
          description: ['at last, sunset at Pre Rip'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `On the way back, we stop by Angkor Wat to take a breather. We observe the lake but also keep an eye on the monkeys close to us. I hope we will be strong enough for the ride back. The whole loop took more than 40 kilometers + we walked many many temple stairs.`,
        `Biking home, we find a local market with no tourists. There are typical dance performances with beautiful costumes on the stage. Diana is enjoying it - she has never-ending energy! I am sitting on the ground and looking from afar. I am getting cramps in my legs. And in my belly.`,
        `We get home and I get my period. I drink my electrolytes and take a shower with a lot of difficulties. Diana and I go for a light salad dinner on the rooftop. My upset body would not handle a heavier dinner. I share my electrolytes with Diana - she needs some too. Before we go to sleep, we stay sitting on a bench in front of the hostel room and can’t help but keep talking.`
      ]
    },
    {
      type: 'text',
      value: [
        `This morning, I am saying goodbye to Diana, another impactful woman in my travels. I am feeling destroyed from the giant bike ride, the heat, and my period.`,
        `Still, I make it to my third acupuncture appointment. Today, the session feels productive again. Something is moving in my back. I tell the doctor the pain is probably improving. She, however, is not satisfied with this answer and supports me to come to more appointments. I want to be careful. She also wants to prescribe me a lot of chinese medicine for a lot of money, which feels wrong. I tell her I plan to get rid of my back pain with more exercise. She respects my decision and even shows me a nice flowy exercise to get the stuck wind moving again.`,
        `It is hard to tell whether acupuncture has helped. I feel like the pain is improving, but we could easily attribute that to all the yoga practice I am doing almost daily.`,
        `I get back to the hostel and realise I cannot go to Angkor today. I am too weak. Instead, I take this day to swim in the pool, read, write, relax… In the late evening, my body completely gives up. I get sick and spend most of my night in the bathroom - until I finally get the strength to shower and go lay in bed in the early morning.`
      ]
    },
    {
      type: 'text',
      value: [
        `Sunday is just about survival. I go nowhere today. Throughout the day, I have a few rounds when I need to go to the bathroom. I no longer think this only has to do with hot weather, physical tiredness, or period. I ate something bad again.`
      ]
    },
    {
      type: 'text',
      value: [
        `On Monday morning, I feel a little bit better. Whenever I think about eggs, I want to puke. Why eggs? Could my sickness be from a bad egg?`,
        `Somehow, I do not know how, I manage to get on my bike in the afternoon and ride to Angkor. I listen to podcasts to distract me from feeling sick. I just do not want to spend another day at home. It might be good for my body but not for my mind. I really want to see Angkor Wat - the main temple.`,
        `I take it very slowly. Whenever possible, I take a long break in the shadow. Despite my bad condition, I am amazed how big and impressive Angkor Wat is. At some point, my heart starts hurting and I understand Angkor Wat is the only temple I am seeing today.`
      ]
    },
    {
      type: 'text',
      value: [
        `On Tuesday, I stay in again. On Wednesday, I feel a bit better and get on my bike again. Let’s see some temples again!`,
        `I share a heartfelt conversation with the ticket guy. We talk about travelling and visiting other countries, dreams and goals.`,
        `‘You are lucky your dream came true!’ he says.`,
        `When I am back on my bike and entering the complex, he shouts at me ‘I love you!’ and to my surprise, I do not hesitate to shout it back. Only good vibes came out of that guy.`,
        `Bayon temple is a very special temple with 216 giant smiling faces engraved in the stone. The temple is partially Buddhist and partially Hindu. Some faces are of Buddha, others of Hindu gods, even on the same pillar - which possibly represents the peace and unity between the religions.`,
        `The next temple is Ta Prohm temple. I barely manage to get there as my bike gets a flat tire. This temple has been overtaken by the jungle. The trees are all wrapped around the temples, sometimes breaking them in half. People decided to respect nature and leave it like this. It looks really impressive and dramatic.`,
        `After visiting the temple, I need to take care of the broken bike. I realize I am not able to call from my phone. I ask a worker to call the hostel for me (since they are the ones who lent me the bike). The worker hangs up after only a few seconds of dialing, and it takes a lot of effort to convince him to wait a little. When they finally pick up, the communication is really difficult.`,
        `First, they promise they will send someone. I start strolling around and killing time, refusing all kinds of souvenirs that are being pushed to my hands.`,
        `I end up waiting about an hour and I am getting nervous. I am supposed to start working soon. I find the worker again and ask him to call again. Now the hostel says no one is coming. I get really upset and start searching for a tuktuk big enough to bring me and my bike home. I do not even know how, who he is and who called him, but a young guy arrives on a motorbike and promises to bring me home. So somehow I am being helped after all!`,
        `This boy tells me I will be reimbursed the money for the bike for today - nice. Then he stops by a store and asks me to sit down and get some drinks. I do not really want to sit down or get anything, I just want to get home asap. He then asks me to buy him some water but I do not have any money on me. I can only offer 20$ but they do not have change. Eventually, he gets something from the store to fix the hole in the bike.`,
        `Now we are back on the motorbike and I am relieved we are on the way. He drives with one hand and holds the bicycle with the other.`,
        `We get to the hostel and I am all tired and sweaty. I am all stressed out. I do not feel like a human. I want to apologise if I sounded frustrated on the phone. But the staff seems like they gossip and laugh when I arrive and talk to each other in their language while I try to talk to them. I get even more uncomfortable. I lose will to be kind. The best I can do at this moment is to ignore them.`,
        `Even though the motorbike boy promised me I would get some money, after a conversation with the staff, he turns the story around, and asks me to pay him now. I do not ask questions and just give him the money. All I want to do at this point is to take a shower and get to work.`,
        `I have to work a bit late tonight but at least I am finding some calm again.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post16/9.jpg',
          description: ['one of many Angkor Wat\'s statues'],
        },
        {
          src: '/images/01_southeast_asia/post16/10.jpg',
          description: ['enjoying the shade on the sides of a temple'],
        },
        {
          src: '/images/01_southeast_asia/post16/11.jpg',
          description: ['monkeys were a big part of Angkor Wat'],
        },
        {
          src: '/images/01_southeast_asia/post16/12.jpg',
          description: ['the temple complex was huge'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `The last day, I shower, pack, and at this point, I am pretty excited to leave the hostel. I had my share of fun here, but also I experienced a lot of sickness and since Claudia and Diana left, it suddenly felt so empty. After the bike incident, I do not feel like the staff is kind anymore.`,
        `For work, I head out straight to Starbucks. I take a break at the park where I watch people run and exercise in the crazy heat. Admirable. I get some medicine in the pharmacy and collect my courage to travel on a night bus while my digestion is still not perfect.`,
        `I managed to book a single bunk bed on this sleeper bus on the bottom. Somehow, I end up sleeping quite well! The only sad part is that at 5am, they kick us out of the bus in Phnom Penh. I try to go to the toilet at the ‘station’. It is not really a station, more like an old house with family inside providing chairs for waiting customers, and selling a bit of groceries. Before I can use the toilet, a giant cockroach chases me away.`,
        `The van picks me up together with other passengers. We circle around the town a lot before we finally embark towards Sihanoukville. I am at the port at around 10:40, and have until 12:00 until my boat. At least I take care of myself and my hygiene before I take the ferry onto a very exciting place: the Cambodian Island called Koh Rong Samloem. A place that becomes an overcompensation for my sickness, broken bike, and bad communication with the hostel staff. A real natural paradise.`
      ]
    },
  ],
  signature: '29/03/2024 - 04/04/2024'
}

export default post16
